<ng-container [ngSwitch]="template">
  <ng-container *ngSwitchCase="'header'">
    <ng-container *ngTemplateOutlet="Header"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'slider'">
    <ng-container *ngTemplateOutlet="Slider"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'activitys'">
    <ng-container *ngTemplateOutlet="Activitys"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'featured'">
    <ng-container *ngTemplateOutlet="News"></ng-container>
    <ng-container *ngTemplateOutlet="Recommended"></ng-container>
    <ng-container *ngTemplateOutlet="Projects"></ng-container>
    <ng-container *ngTemplateOutlet="News"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'museums'">
    <ng-container *ngTemplateOutlet="Museums"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'services'">
    <ng-container *ngTemplateOutlet="Services"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'multimedia'">
    <ng-container *ngTemplateOutlet="Multimedia"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'footer'">
    <ng-container *ngTemplateOutlet="Footer"></ng-container>
  </ng-container>
</ng-container>

<ng-template #Header>
  <app-skeleton type="title" length="large" size="xl"></app-skeleton>
  <div class="row">
    <div class="medium-1">
      <app-skeleton type="image" width="60" height="60" round="true"></app-skeleton>
    </div>
    <div class="medium-4">
      <app-skeleton type="paragraph" size="l"></app-skeleton>
      <app-skeleton type="paragraph" length="large" size="l"></app-skeleton>
    </div>
    <div class="medium-12">
      <app-skeleton type="paragraph" length="large" size="l"></app-skeleton>
    </div>
  </div>
</ng-template>
<ng-template #Slider>
  <app-skeleton type="image" height="900"></app-skeleton>
</ng-template>
<ng-template #Activitys>
  <div class="row">
    <div class="medium-9 columns">
      <app-skeleton type="title" length="small" size="xl"></app-skeleton>
    </div>
    <div class="medium-3 columns">
      <app-skeleton type="paragraph" length="large" size="l"></app-skeleton>
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let node of [0,1,2,3]">
      <div class="medium-3 small-12 columns">
        <app-skeleton type="paragraph" length="small"></app-skeleton>
        <app-skeleton type="title" length="large" size="l"></app-skeleton>
        <app-skeleton type="image" height="505"></app-skeleton>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="medium-2 medium-offset-5 columns">
      <app-skeleton type="image" height="60"></app-skeleton>
      <app-skeleton type="paragraph"></app-skeleton>
    </div>
  </div>
</ng-template>
<ng-template #News>
  <div class="row">
    <div class="medium-9 columns">
      <app-skeleton type="title" length="small" size="xl"></app-skeleton>
    </div>
    <div class="medium-3 columns">
      <app-skeleton type="paragraph" length="large" size="l"></app-skeleton>
    </div>
  </div>
  <div class="row">
    <div class="small-12 columns medium-6">
      <app-skeleton type="image" height="379"></app-skeleton>
      <app-skeleton type="paragraph" length="small"></app-skeleton>
      <app-skeleton type="title" size="l"></app-skeleton>
      <app-skeleton type="paragraph" length="large"></app-skeleton>
    </div>
    <div class="medium-3 small-12 columns">
      <app-skeleton type="image" height="180"></app-skeleton>
      <app-skeleton type="paragraph"></app-skeleton>
      <app-skeleton type="title" length="large" size="l"></app-skeleton>
      <app-skeleton type="paragraph" length="large"></app-skeleton>
    </div>
    <div class="small-12 columns third- medium-3">
      <ng-container *ngFor="let node of [0,1,2]">
        <div class="row">
          <div class="small-7 columns">
            <div class="news-information">
              <app-skeleton type="paragraph"></app-skeleton>
              <app-skeleton type="title" length="large"></app-skeleton>
            </div>
          </div>
          <div class="small-5 columns">
            <app-skeleton type="image" height="63"></app-skeleton>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #Recommended>
  <div class="row">
    <div class="medium-12 columns">
      <app-skeleton type="title" length="small" size="xl"></app-skeleton>
    </div>
  </div>
  <div class="row">
    <div class="medium-6 small-12 column pd-0">
      <ng-container *ngFor="let node of [0,1]">
        <div class="medium-6 small-12 columns">
          <app-skeleton type="image" height="169"></app-skeleton>
          <app-skeleton type="paragraph" length="small"></app-skeleton>
          <app-skeleton type="title" size="l"></app-skeleton>
          <app-skeleton type="paragraph" length="large"></app-skeleton>
        </div>
      </ng-container>
    </div>
    <div class="medium-6 small-12 column pdl-0">
      <div class="row">
        <ng-container *ngFor="let node of [0,1]">
          <div class="medium-6 small-12 column">
            <ng-container *ngFor="let node of [0,1]">
              <div class="third-destacado card-ac">
                <div class="row">
                  <div class="small-7 columns">
                    <div class="news-information">
                      <app-skeleton type="paragraph"></app-skeleton>
                      <app-skeleton type="title" length="large"></app-skeleton>
                    </div>
                  </div>
                  <div class="small-5 columns">
                    <app-skeleton type="image" height="63"></app-skeleton>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #Projects>
  <div class="row">
    <div class="medium-12 columns">
      <app-skeleton type="title" length="small" size="xl"></app-skeleton>
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let node of [0,1,2,3]">
      <div class="medium-3 small-12 columns">
        <app-skeleton type="image" height="169"></app-skeleton>
        <app-skeleton type="paragraph" length="small"></app-skeleton>
        <app-skeleton type="title" size="l"></app-skeleton>
        <app-skeleton type="paragraph" length="large"></app-skeleton>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #Museums>
  <div class="row">
    <div class="medium-9 columns">
      <app-skeleton type="title" length="small" size="xl"></app-skeleton>
    </div>
    <div class="medium-3 columns">
      <app-skeleton type="paragraph" length="large" size="l"></app-skeleton>
    </div>
  </div>
  <div class="row expanded">
    <ng-container *ngFor="let node of [0,1]">
      <div class="small-12 medium-6 end">
        <app-skeleton type="image" height="600"></app-skeleton>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #Services>
  <div class="row">
    <div class="medium-9 columns">
      <app-skeleton type="title" length="small" size="xl"></app-skeleton>
    </div>
    <div class="medium-3 columns">
      <app-skeleton type="paragraph" length="large" size="l"></app-skeleton>
    </div>
  </div>
  <ng-container *ngFor="let node of [0,1]">
    <div class="row">
      <div class="medium-12 small-12 columns">
        <app-skeleton type="title" length="large" size="l"></app-skeleton>
      </div>
    </div>
    <div class="row">
      <ng-container *ngFor="let node of [0,1,2,3,4,5]">
        <div class="small-6 medium-4 columns end">
          <div class="row">
            <div class="medium-3 small-12 columns">
              <app-skeleton type="image" height="44"></app-skeleton>
            </div>
            <div class="medium-9 small-12 columns">
              <app-skeleton type="title" size="l"></app-skeleton>
              <app-skeleton type="paragraph" length="large"></app-skeleton>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
<ng-template #Multimedia>
  <div class="row">
    <div class="medium-9 columns">
      <app-skeleton type="title" length="small" size="xl"></app-skeleton>
    </div>
    <div class="medium-3 columns">
      <app-skeleton type="paragraph" length="large" size="l"></app-skeleton>
    </div>
  </div>
  <div class="row">
    <div class="small-12 medium-6 columns end">
      <app-skeleton type="image" height="363"></app-skeleton>
    </div>
    <div class="small-12 medium-6 columns end">
      <ng-container *ngFor="let node of [0,1,2]">
        <div class="small-12 medium-12 columns end">
          <div class="row">
            <div class="medium-4 small-12 columns">
              <app-skeleton type="image" height="100"></app-skeleton>
            </div>
            <div class="medium-8 small-12 columns">
              <app-skeleton type="title" size="l"></app-skeleton>
              <app-skeleton type="paragraph" length="large"></app-skeleton>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #Footer>
  <footer>
    <div class="row">
      <div class="medium-1 small-12 columns">
        <app-skeleton type="image" width="60" height="60" round="true"></app-skeleton>
      </div>
      <div class="medium-3 small-12 columns about">
        <ul>
          <li>
              <app-skeleton type="paragraph"></app-skeleton>
          </li>
          <li>
            <app-skeleton type="paragraph"></app-skeleton>
          </li>
          <li>
            <app-skeleton type="paragraph"></app-skeleton>
          </li>
          <li>
            <app-skeleton type="paragraph"></app-skeleton>
          </li>
          <li>
            <app-skeleton type="paragraph"></app-skeleton>
          </li>
          <li>
            <app-skeleton type="paragraph"></app-skeleton>
          </li>
          <li>
            <app-skeleton type="paragraph" length="large"></app-skeleton>
          </li>
          <li>
            <app-skeleton type="paragraph"></app-skeleton>
          </li>
        </ul>
        <div class="social">
          <div class="row">
            <div class="medium-3"><app-skeleton type="paragraph"></app-skeleton></div>
            <div class="medium-3"><app-skeleton type="paragraph"></app-skeleton></div>
            <div class="medium-3"><app-skeleton type="paragraph"></app-skeleton></div>
            <div class="medium-3"><app-skeleton type="paragraph"></app-skeleton></div>
          </div>
        </div>
      </div>
      <div class="medium-3 small-12 columns help hide-for-small-only">
        <app-skeleton type="title" length="large" size="l"></app-skeleton>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" length="large" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" length="large" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" length="large" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
      </div>
      <div class="medium-5 small-12 columns contact hide-for-small-only">
        <app-skeleton type="title" length="large" size="l"></app-skeleton>
        <div>
          <app-skeleton type="paragraph" length="large" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" length="small" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" length="small" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
        <div>
          <app-skeleton type="paragraph" size="s"></app-skeleton>
        </div>
      </div>
    </div>
    <div class="row copyright">
      <div class="medium-4 medium-offset-8"><app-skeleton type="paragraph" length="large"></app-skeleton></div>
    </div>
  </footer>
</ng-template>