import { Component, OnInit, AfterViewChecked, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-menu-citys',
  templateUrl: './menu-citys.component.html',
  styleUrls: ['./menu-citys.component.scss'],
})
export class MenuCitysComponent implements OnInit, AfterViewChecked {
  @Input() option: number = 1;
  @Input() nodes: any;
  public foundation: boolean = false;
  public form: FormGroup;
  public citys = [];

  constructor(private router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.citys = [{name: 'Selecciona tu ciudad', value: '', selected: true}];
    this.nodes.forEach(function(value: any, index: number) {
      this.citys.push({name: value.name, value: value.path+(value.fragment?'#'+value.fragment:''), selected: false});
   }, this);

    this.form = new FormGroup({
      city: new FormControl('')
    });
  }

  ngAfterViewChecked(): void {
    if ( !this.foundation ) {
      this.foundation = true;
    }
  }

  find(value) {
    if(value.city){
      let pos = value.city.indexOf('#');
      if(pos > -1){
        this.router.navigate([value.city.slice(0, pos)], {fragment: value.city.slice(pos+1, value.city.length)});
      }else {
        this.router.navigate([value.city]);
      }
    }
    return false;
  }
}
