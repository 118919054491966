import { Component, OnInit, Input, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Router, RoutesRecognized, NavigationEnd } from '@angular/router';
import { MenuService } from './menu.service';
import { CommonFunctions } from '../../app.common';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [ MenuService ],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, AfterViewChecked {
	
  @Input() language: string = 'es';
  @Input() search: boolean = false;
	public foundation: boolean = false;
  public searchActive: boolean = false;
  public isMobile: boolean = false;
  public form: FormGroup;
  public home = '';
  public menu: any;
  public menuheader: any;
  public url = '';
  public env: any;
  public ready: boolean = false;
  public readymh: boolean = false;

  constructor( private router: Router, private Service: MenuService, private fb: FormBuilder, private commonFunctions: CommonFunctions ) {
    this.env = environment;
    this.isMobile = this.commonFunctions.isMobile();

    this.Service.getData({name: 'main-menu-front'})
    .subscribe(
      data => {
        data.forEach(function(v, k) {
          if(v.childs){
            data[k].childs = v.childs.sort((a, b) => a['weight']-b['weight'])
          }
        });
        this.menu = data.sort((a, b) => a['weight']-b['weight'])
      },
      err  => console.log(),
      () => this.ready = true
    );

    if(this.isMobile){
      this.Service.getData({name: 'secondary-menu-front'})
      .subscribe(
        data => {
          data.forEach(function(v, k) {
            if(v.childs){
              data[k].childs = v.childs.sort((a, b) => a['weight']-b['weight'])
            }
          });
          this.menuheader = data.sort((a, b) => a['weight']-b['weight'])
        },
        err  => console.log(),
        () => this.readymh = true
      );
    }

    this.router.events.subscribe( (val) => {
      const self = this;
      if (val instanceof RoutesRecognized) {
        const url = val.url.replace(/^\//, '');
        const params = val.url.replace(/^\//, '').split('/');
        if(url === '' && params[0] === ''){
         this.home = 'banrepcultural';
        }else if(val.url.indexOf('buscar') > -1){
         this.home = 'buscar'; 
        }else if(val.url.indexOf('biblioteca-virtual') > -1){
         this.home = 'biblioteca-virtual';
        }else if (params[0].match(/^#centros-culturales/) || params[0].match(/^#museos/) || params[0].match(/^#bibliotecas/) || params[0].match(/^#colecciones/) || params[0].match(/^#especiales/) || params[0].match(/^#servicios/) || params[0].match(/^#actividades/) || params[0].match(/^#buscador-general/) || params[0].match(/^#buscador-catalogo/) || params[0].match(/^#bibliotecavirtual/)) {
          this.home = 'banrepcultural';
        }else {
          this.home = '';
        }
      }
      if(val instanceof NavigationEnd) {
        let pathname = window.location.pathname, hash = window.location.hash;
        if(pathname === '' || pathname === '/') {
          this.url = '';
        }else {
          this.url = pathname;
        }
      }
    });
  }
  
  ngOnInit(): void {
    this.form = new FormGroup({
      query: new FormControl('')
    });
  }

  ngAfterViewChecked(): void {
    if ( !this.foundation && this.ready ) {
      this.foundation = true;
      $('app-menu').foundation();

      if($('#mainmenu').length > 0){
        $('#mainmenu ul ul').attr('role', 'menu');
      }

      $('#mainmenu')
      .on('mouseover', 'ul', function(){
        $(this).addClass('active');
      })
      .on('mouseout', 'ul', function(){
        $(this).removeClass('active');
      });

      /*$('#mainmenu')
        .on('mouseover', '.main-item-search', function(){
          $(this).addClass('active');
          $('.main-item-search-dp').addClass('show-item-search');
        })
        .on('mouseout', '.main-item-search.homes', function(){
          $(this).removeClass('active');
          $('.main-item-search-dp').removeClass('show-item-search');
        });
      $('#mainmenu')
        .on('mouseover', '.main-item-search-dp', function(){
          $('.main-item-search').addClass('active');
          $('.main-item-search-dp').addClass('show-item-search');
        })
        .on('mouseout', '.main-item-search-dp', function(){
          $('.main-item-search').removeClass('active');
          $('.main-item-search-dp').removeClass('show-item-search');
        })
        .on('click', '.main-item-search-dp', function(e){
          $('.main-item-search-dp').addClass('show-item-search');
        });*/

      $('#mainmenuresp .mmr-3')
        .on('click', 'i.icon-search', function(e){
          if($('#mainmenuresp  .mmr-3 i.icon-search').hasClass('active')){
            $('#mainmenuresp  .mmr-3 i.icon-search').removeClass('active');
            $('#searchSmall').css('display', 'none').attr('name', 'disable');
          }else {
            $('#mainmenuresp  .mmr-3 i.icon-search').addClass('active');
            $('#searchSmall').css('display', 'block').attr('name', 'enable');
          }
        });
      $('#mainmenuresp')
        .on('click', 'button.menu-icon', function(e){
          $('body').addClass('menuopen');
          $('#warning-time').addClass('hide');
          $('#mainmenuresp button.menu-icon').addClass('hide');
          $('#mainmenuresp button.icon-cancel').removeClass('hide');
          $('#menu-resp').removeClass('hide').css('display', 'block');
        });
      $('#mainmenuresp')
        .on('click', 'button.icon-cancel', function(e){
          $('body').removeClass('menuopen');
          $('#warning-time').removeClass('hide');
          $('#mainmenuresp button.menu-icon').removeClass('hide');
          $('#mainmenuresp button.icon-cancel').addClass('hide');
          $('#menu-resp').addClass('hide').css('display', 'none');
        });
      $('#menu-resp')
        .on('click', '.menu a', function(e){
          if($(this).attr('href') !== '#') {
            $('.title-bar').foundation('toggleMenu');
            $('body').removeClass('menuopen');
            $('#mainmenuresp button.menu-icon').removeClass('hide');
            $('#mainmenuresp button.icon-cancel').addClass('hide');
            $('#menu-resp').addClass('hide').css('display', 'none');
          }
          if($('.menu.menu-item-bibliotecas.is-active').length > 0) {
            $('#menu-finder').removeClass('hide');
            $('#menu-citys').removeClass('hide');
          }else {
            $('#menu-finder').addClass('hide');
            $('#menu-citys').addClass('hide');
          }
        })
        .on('click', '.submenu a', function(e){
          $('.title-bar').foundation('toggleMenu');
          $('body').removeClass('menuopen');
          $('#mainmenuresp button.menu-icon').removeClass('hide');
          $('#mainmenuresp button.icon-cancel').addClass('hide');
          $('#menu-resp').addClass('hide').css('display', 'none');
        });
        
      /*Header fixed*/
      $(window).scroll(function(){
        var sticky = $('#mainmenu'), scroll = $(window).scrollTop();

        if (scroll >= 100) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
      });
    }
  }

  doFind(value, event) {
    $('.main-item-search').removeClass('active');
    $('.main-item-search-dp').removeClass('show-item-search');
    this.router.navigate(['/buscar'], {queryParams: {term: value.query}});
  }

  doFindSmall(value, event) {
    const $ = jQuery;
    $('#searchSmall').css('display', 'none').attr('name', 'disable');
    this.router.navigate(['/buscar'], {queryParams: {term: value.query}});
  }

  goToFinder() {
    this.searchActive = this.searchActive ? false : true;
  }
}
