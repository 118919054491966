import { Component, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'scripts',
  template: '',
})
export class ScriptsComponent implements AfterViewInit {

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    window.onload = () => {
      this.loadGoogleAnalytics();
      this.loadGoogleTagManager();
    };
  }

  loadGoogleAnalytics(): void {
    const script = this.renderer.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-XXXXXXXXX-X';
    script.async = true;
    this.renderer.appendChild(document.body, script);

    const inlineScript = this.renderer.createElement('script');
    inlineScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'UA-XXXXXXXXX-X');
    `;
    this.renderer.appendChild(document.body, inlineScript);
  }

  loadGoogleTagManager(): void {
    const script = this.renderer.createElement('script');
    script.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-XXXXXXX');
    `;
    this.renderer.appendChild(document.body, script);
  }
}
