import { Component, OnInit, AfterViewChecked, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-menu-seeker',
  templateUrl: './menu-seeker.component.html',
  styleUrls: ['./menu-seeker.component.scss'],
})
export class MenuSeekerComponent implements OnInit, AfterViewChecked {
  @Input() option: number = 1;
  public foundation: boolean = false;
  public form: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      finder: new FormControl('')
    });
  }

  ngAfterViewChecked(): void {
    if ( !this.foundation ) {
      this.foundation = true;
      $('app-menu-seeker').foundation();
    }
  }

  find(value) {
    if(value.finder && value.finder !== '') {
      var params = [];
      params = new Array('query=any,contains,' + value.finder, 'tab=ALL', 'search_scope=MyInst_and_CI', 'vid=57BDLRDC_INST:57BDLRDC_INST', 'lang=es');
      window.open(environment.descubridor.path + 'discovery/search?'+params.join('&'), '_blank');
      return true;
    }else {
      return false;
    }
  }
}
