import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ActivitysComponent } from './activitys/activitys.component';
import { ActivitysFormComponent } from './activitys-form/activitys-form.component';
import { AssociationComponent } from './association/association.component';
import { BrcLinkDirective } from '../services/brc-link.directive';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CardActivityComponent } from './card-activity/card-activity.component';
import { CardContentComponent } from './card-content/card-content.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { CollectionsRelatedComponent } from './collections-related/collections-related.component';
import { CollectionsCarrouselComponent } from './collections-carrousel/collections-carrousel.component';
import { ImageComponent } from './components/image/image.component';
import { TabPanelComponent } from './components/tab-panel/tab-panel.component';
import { TabTriggerComponent } from './components/tab-trigger/tab-trigger.component';
import { ContentComponent } from './content/content.component';
import { ContentDatesComponent } from './content-dates/content-dates.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { DataSheetComponent } from './data-sheet/data-sheet.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { FeaturedComponent } from './featured/featured.component';
import { FooterComponent } from './footer/footer.component';
import { FormsComponent } from './forms/forms.component';
import { GalleryContentComponent } from './gallery-content/gallery-content.component';
import { HeaderComponent } from './header/header.component';
import { HeaderLayoutComponent } from './header-layout/header-layout.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { MapComponent } from './map/map.component';
import { MenuComponent } from './menu/menu.component';
import { MenuLinkComponent } from './menu/menu-link/menu-link.component';
import { MenuSeekerComponent } from './menu/menu-seeker/menu-seeker.component';
import { MenuCitysComponent } from './menu/menu-citys/menu-citys.component';
import { MessageComponent } from './message/message.component';
import { MiscellanyComponent } from './miscellany/miscellany.component';
import { MultimediaComponent } from './multimedia/multimedia.component';
import { MuseumsComponent } from './museums/museums.component';
import { NewsGridComponent } from './news-grid/news-grid.component';
import { OwlCarouselComponent } from './owl-carousel/owl-carousel.component';
import { PaginationComponent } from './pagination/pagination.component';
import { RecommendedGridComponent } from './recommended-grid/recommended-grid.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { SeekerComponent } from './seeker/seeker.component';
import { SeekerMobileComponent } from './seeker/seeker-mobile/seeker-mobile.component';
import { ServicesComponent } from './services/services.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SliderComponent } from './slider/slider.component';
import { SliderMobileComponent } from './slider-mobile/slider-mobile.component';
import { SocialComponent } from './social/social.component';
import { SpacesComponent } from './spaces/spaces.component';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { StringToArray, HtmlSecure, WeekDay, Limited, Quotes, DateRecurent, DateRecurentEvent, HoursToDate, RemoveElementsHtml, FormatStyleImage, Ampersand } from '../pipes/pipes';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { SkeletonContainerComponent } from './skeleton-container/skeleton-container.component';
import { ScriptsComponent } from './scripts/scripts.component';

@NgModule({
  imports: [
    CommonModule,
  	FormsModule,
    ReactiveFormsModule,
  	RouterModule,
  	HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  declarations: [
    ActivitysComponent,
    ActivitysFormComponent,
    AssociationComponent,
    BrcLinkDirective,
    BreadcrumbsComponent,
    CardActivityComponent,
    CardContentComponent,
    ChatbotComponent,
    CollectionsRelatedComponent,
    CollectionsCarrouselComponent,
    ContentComponent,
    ContentDatesComponent,
    CookiesPolicyComponent,
    DataSheetComponent,
    DatepickerComponent,
  	FooterComponent,
    FormsComponent,
    GalleryContentComponent,
    FeaturedComponent,
  	HeaderComponent,
    HeaderLayoutComponent,
    LightboxComponent,
    MapComponent,
    MenuComponent,
    MenuLinkComponent,
    MenuSeekerComponent,
    MenuCitysComponent,
    MessageComponent,
    MiscellanyComponent,
    MultimediaComponent,
  	MuseumsComponent,
    NewsGridComponent,
    OwlCarouselComponent,
    PaginationComponent,
    RecommendedGridComponent,
    ScheduleComponent,
    SeekerComponent,
    SeekerMobileComponent,
  	ServicesComponent,
    SidebarComponent,
  	SliderComponent,
    SliderMobileComponent,
    SocialComponent,
    SpacesComponent,
    SubMenuComponent,
    SubscribeComponent,
    StringToArray,
    HtmlSecure,
    WeekDay,
    Limited,
    Quotes,
    DateRecurent,
    DateRecurentEvent,
    HoursToDate,
    RemoveElementsHtml,
    FormatStyleImage,
    Ampersand,
    ImageComponent,
    TabPanelComponent,
    TabTriggerComponent,
    SkeletonComponent,
    SkeletonContainerComponent,
    ScriptsComponent,
  ],
  exports: [
    CommonModule,
  	FormsModule,
  	RouterModule,
  	HttpClientModule,
    ActivitysComponent,
    ActivitysFormComponent,
    AssociationComponent,
    BrcLinkDirective,
    BreadcrumbsComponent,
    CardActivityComponent,
    CardContentComponent,
    ChatbotComponent,
    CollectionsRelatedComponent,
    CollectionsCarrouselComponent,
    ContentComponent,
    ContentDatesComponent,
    CookiesPolicyComponent,
    DataSheetComponent,
    DatepickerComponent,
  	FooterComponent,
    FormsComponent,
    GalleryContentComponent,
    FeaturedComponent,
  	HeaderComponent,
    HeaderLayoutComponent,
    LightboxComponent,
    MapComponent,
    MenuComponent,
    MenuLinkComponent,
    MenuSeekerComponent,
    MenuCitysComponent,
    MessageComponent,
    MiscellanyComponent,
    MultimediaComponent,
  	MuseumsComponent,
    NewsGridComponent,
    OwlCarouselComponent,
    PaginationComponent,
    RecommendedGridComponent,
    ScheduleComponent,
    SeekerComponent,
    SeekerMobileComponent,
  	ServicesComponent,
    SidebarComponent,
  	SliderComponent,
    SliderMobileComponent,
    SocialComponent,
    SpacesComponent,
    SubMenuComponent,
    SubscribeComponent,
    StringToArray,
    HtmlSecure,
    WeekDay,
    Limited,
    Quotes,
    DateRecurent,
    DateRecurentEvent,
    HoursToDate,
    RemoveElementsHtml,
    FormatStyleImage,
    Ampersand,
    ImageComponent,
    TabPanelComponent,
    TabTriggerComponent,
    ScriptsComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
