import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-seeker-mobile',
  templateUrl: './seeker-mobile.component.html',
  styleUrls: ['./seeker-mobile.component.scss'],
})
export class SeekerMobileComponent implements OnInit, AfterViewChecked {
  @Input() language: string = 'es';
  public foundation: boolean = false;
  public search: boolean = false;

  ngOnInit(): void {
    $(document).ready(function() {
      if($('#searchSmall input').length) {
        $('#searchSmall input').keypress(function(e) {
          if (e.which === 13) {
            $('#seeker-mobile').removeClass('active');
            $('#searchSmall').removeClass('active');
          }
        });
        $('#searchSmall button').click(function() {
          $('#seeker-mobile').removeClass('active');
          $('#searchSmall').removeClass('active');
        });
      }
    });
  }

  ngAfterViewChecked(): void {
    if ( !this.foundation ) {
      this.foundation = true;
      $('app-seeker-mobile').foundation();
    }
  }

  openSearch() {
    this.search = this.search ? false : true;
  }
}
