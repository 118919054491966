import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {  
  @Input() type: 'title' | 'paragraph' | 'image' = 'paragraph';
  @Input() length: 'small' | 'medium' | 'large' = 'medium';
  @Input() size: 's' | 'm' | 'l' | 'xl' = 'm';
  @Input() width: string = '100%';
  @Input() height: string = '100%';
  @Input() round: string = '';

  getSkeletonClasses(): any {
    return {
      'skeleton': true,
      'skeleton-title': this.type === 'title',
      'skeleton-paragraph': this.type === 'paragraph',
      'skeleton-image': this.type === 'image',
      'small': this.length === 'small',
      'medium': this.length === 'medium',
      'large': this.length === 'large',
      's': this.size === 's',
      'm': this.size === 'm',
      'l': this.size === 'l',
      'xl': this.size === 'xl',
      'round': this.round === 'true',
    };
  }
}
