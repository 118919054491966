<app-skeleton-container *ngIf="skeleton" template="header"></app-skeleton-container>
<ng-container *ngIf="!skeleton">
  <header [attr.vh]="vh">
    <ng-container *ngIf="ready">
      <ng-container [ngSwitch]="vh">
        <ng-container *ngSwitchCase="'2'"> 
          <ng-container *ngTemplateOutlet="vh2"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'3'"> 
          <ng-container *ngTemplateOutlet="vh2"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'4'"> 
          <ng-container *ngTemplateOutlet="vh2"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault> 
          <ng-container *ngIf="!isMobile">
            <div class="top" [ngClass]="{'hide': hidesecondarymenu}">
              <div class="row">
                <div class="medium-12 columns top-left">
                  <div class="top-list-menu">
                    <ng-container *ngTemplateOutlet="smc"></ng-container>
                    <ul class="content-links">
                      <ng-container *ngFor="let item of menu; let ic = index; let ec = even; let oc = odd; let fc = first; let lc = last;">
                        <li>
                          <app-menu-link [item]="item" [language]="language"></app-menu-link>
                        </li>
                      </ng-container>
                      <li *ngIf="results">
                        <div class="notifications-content tooltip"><div class="notifications" [ngClass]="{'active': active}" (click)="openMessage()"></div>
                          <span class="bottom">{{title}}<i></i></span>
                        </div>
                      </li>
                    </ul>
                    <ng-container *ngIf="env?.descubridor?.active">
                      <app-seeker [home]="'header'" [language]="language"></app-seeker>
                    </ng-container>
                    <ul class="social-links">
                      <li><a [routerLink]="'/redes'" [fragment]="'facebook'" class="icfb">Banrepcultural en Facebook</a></li>
                      <li><a [routerLink]="'/redes'" [fragment]="'youtube'" class="icyt">Banrepcultural en YouTube</a></li>
                      <li><a [routerLink]="'/redes'" [fragment]="'instagram'" class="icig">Banrepcultural en Instagram</a></li>
                      <li><a [routerLink]="'/redes'" [fragment]="'twitter'" class="ictw">Banrepcultural en Twitter</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="warningtime"></ng-container>
            <div class="row" [ngClass]="{'hide': hidesecondarymenu}">
              <div class="medium-5 columns end">
                <a href="/" title="Inicio">
                  <img class="logo-brc lazyload" [attr.data-src]="'/assets/images/brc.svg'" alt="Logo de La Red Cultural del Banco de la República, lleva a la página de inicio" title="Logo de La Red Cultural del Banco de la República" width="410px" height="70px">
                </a>
              </div>
            </div>
            <app-menu [language]="language"></app-menu>
          </ng-container>
          <ng-container *ngIf="isMobile">
            <div class="row">
              <div class="small-1 columns mmr">
                <app-menu [language]="language"></app-menu>
              </div>
              <div class="small-9 columns mmr">
                <a [routerLink]="['/']" title="Inicio">
                    <img class="lazyload logo-brc-resp" [attr.data-src]="'/assets/images/brc.svg'" alt="La Red Cultural del Banco de la República" title="La Red Cultural del Banco de la República" [attr.longdesc]="'../#about-img-menum-logo'" width="179" height="50">
                    <div [attr.id]="'about-img-menum-logo'" class="hide"><p>La Red Cultural del Banco de la República</p></div>
                </a>
              </div>
              <div class="small-1 columns mmr">
                <div *ngIf="results" class="notifications-content tooltip">
                  <div class="notifications" (click)="openMessage()"></div>
                </div>
              </div>
              <div class="small-1 columns mmr">
                <app-seeker-mobile></app-seeker-mobile>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="warningtime"></ng-container>
          </ng-container>
          <!--app-message></app-message-->
          <!--a href="https://www.banrep.gov.co/es/centenario" target="_blank" title="100 años Banco de la República">
            <img id="logo-br-100" class="lazyload" [attr.data-src]="'/assets/images/br-100.png'" alt="Logo 100 años Banco de la República" title="Logo 100 años Banco de la República" width="102px" height="50px">
          </a-->
        </ng-container>
      </ng-container>
    </ng-container>
    <div id="main-content">Contenido</div>
  </header>
  <ng-template #vh2>
    <ng-container *ngIf="!isMobile">
      <app-menu [language]="language" [search]="true"></app-menu>
    </ng-container>
    <ng-container *ngIf="isMobile">
      <div id="vh2" [ngClass]="{'white': vh !== 3}">
        <div class="row">
          <div class="medium-8 small-12 columns link">
            <ng-container *ngTemplateOutlet="smc"></ng-container>
            <a href="/" title="Logo de La Red Cultural del Banco de la República">Banrepcultural</a>
          </div>
          <div class="medium-4 columns hide-for-small-only search">
            <app-seeker-mobile></app-seeker-mobile>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #smc>
    <a id="skip-main-content" class="hide" [href]="url" [attr.data-keyindex]="0" [attr.data-keyhash]="hash" tabindex="0">Salto a contenido</a>
  </ng-template>
  <ng-template #warningtime>
    <ng-container *ngIf="show">
      <div id="warning-time">
        <div class="row">
          <div class="columns body">
            <div class="medium-11 small-10 columns notice" [innerHTML]='message'></div>
            <div class="medium-1 small-2 columns more-info">
              <button type="button" class="icon-cancel-circle" aria-label="Close alert" (click)="closeMessage()">
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
