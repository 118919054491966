import { Component, OnInit } from '@angular/core';
import { MetatagsService } from '../services/metatags.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  public env: any;
  public metatags = {
    title: 'Opps! Error 404',
    body: 'Discúlpanos. Al parecer has llegado a una página que no existe.',
    path:'/404',
  };

  constructor(private metatagsService: MetatagsService) { }

  ngOnInit(): void {
    this.env = environment;
    this.metatagsService.updateMetaTags(this.metatags);
  }

}
