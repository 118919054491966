import { Component, AfterViewInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss'],
  providers: [ CookieService ]
})
export class CookiesPolicyComponent implements AfterViewInit {

  public loadComponent: boolean = false;
  public cookie: boolean = false;
  public domain: string = environment.domain;
  public path = '/';
  public secure:  boolean = true;

  constructor(private cookieService: CookieService) {}

  ngAfterViewInit(): void {
    window.onload = () => {
      setTimeout(() => {
        this.loadComponent = true;
        if(!this.cookieService.check('BRC')) {
          this.cookie = false;
        }else {
          if(this.cookieService.get('BRC') === 'false') {
            this.cookie = true;      
            this.deleteScript();      
          }
          if(this.cookieService.get('BRC') === 'true') {
            this.cookie = true;
          }
        }
      }, 0);
    };
  }

  cookieAccept() {
    this.cookie = true;
    let expiry = new Date();
    expiry.setDate(expiry.getDate()+365);
    this.cookieService.set('BRC', 'true', expiry, this.path, this.domain, this.secure, 'Lax');
  }

  cookieNotAccept() {
    this.cookie = true;
    let expiry = new Date();
    expiry.setDate(expiry.getDate()+365);
    this.cookieService.set('BRC', 'false', expiry, this.path, this.domain, this.secure, 'Lax'); 
    this.deleteScript();
  }

  deleteScript(){
    setTimeout(() => {
      Object.keys(this.cookieService.getAll()).forEach(function(index){
        if(index.indexOf('BRC') !== 0) {
          this.cookieService.delete(index);
        }
      },this);
      let po = document.createElement('script');
      po.text = "gtag('consent', 'update', {analytics_storage: 'denied',ad_storage: 'denied'});";
      $('body').append(po);
    }, 1000);
  }
}