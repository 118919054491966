import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-container',
  templateUrl: './skeleton-container.component.html',
  styleUrls: ['./skeleton-container.component.scss'],
})
export class SkeletonContainerComponent {
  @Input() template: string;
}
